<template>
    <div>
        <div id="replacement-modal" v-show="modalReplacementVisible" @click.self="closeModal">
            <div id="content">
                <div class="row">
                    <button class="btn btn-black" @click="closeModal">いますぐ、読替登録を行う</button>
                    <button class="btn btn-black mt-3" @click="moveHome">いまは、読替登録を行わない<br />（次回ログイン時に再度案内）</button>
                </div>
            </div> 
        </div>

        <div class="row mt-3">
            <div class="col-md-7">
                <div class="h5 heading-orange">
                    読替登録
                </div>
            </div>
            <div class="col-md-3">
                <input type="button" class="btn btn-green px-4 py-2" value="介護専用｜サービス登録へ">
            </div>
            <div class="col-md-2">
                <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" v-show="showBackButton" @click="backMenu">
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <levelIcon
                    :set_level="setLevel"
                />
            </div>
            <div class="col-md-5">
                <label class="col-form-label">厚生労働省「介護BCP」を利用される場合は、こちらから登録を行ってください。</label>
            </div>
        </div>
        

        <div class="mt-3">
            TOTONO-Lサイトを利用される前に、呼称の読み替え登録を行います。<br>   
            ここで登録された呼称は、サイト内の設問やヒント表示などの既定呼称を読み替えることができます。
        </div>
        <CommonReplacement
            ref="commonReplacementRef"
            :organization_list = organization_list
            :executive_list = executive_list
            :management_list = management_list
            :business_list = business_list
            :client_list = client_list
            :business_partner_list = business_partner_list
            :supplier_list = supplier_list
            :cooperating_company_list = cooperating_company_list
            :product_list = product_list
            :manufacturing_list = manufacturing_list
            :production_list = production_list
            :delivery_list = delivery_list
            :raw_materials_list = raw_materials_list
            :order_received_list = order_received_list
            :order_list = order_list
            :shipping_list = shipping_list
            :our_company_list = our_company_list
            :company_list = company_list
            :in_house_list = in_house_list
            :plant_list = plant_list
            :employee_list = employee_list
            :employment_list = employment_list
            :attendance_list = attendance_list
            :earnings_list = earnings_list
            :shareholder_list = shareholder_list
            :replacement = replacement
            @confirmSuccess="handleConfirmSuccess"
            @confirmFailed="handleConfirmFailed"
        >
        </CommonReplacement>

        <div class="mt-5">
            <input type="button" class="btn btn-black px-5" value="読み替え登録をする｜確定" @click="confirm" :disabled="CommonReplacement?.isProcessing" v-show="!nextMode" />
            <input type="button" class="btn btn-black px-5" value="次へ進む" @click="moveHome" :disabled="CommonReplacement?.isProcessing" v-show="nextMode" />
            <div class="flash alert color-resilience-orange" v-if="showSuccess">
                {{ successMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import levelIcon from '../common/UserLevelIconsComponent.vue';
import CommonReplacement from "../common/CommonReplacementComponent.vue";
import axios from 'axios';

export default {
    components: {
        levelIcon,
        CommonReplacement,
    },
    setup(props) {
        onMounted(() => {
            if(props.show_back_button === false){
                modalReplacementVisible.value = true;
            }

            showBackButton.value = props.show_back_button;
        })
        const setLevel = ref('111100');

        const modalReplacementVisible = ref(false);

        const closeModal = () => {
            modalReplacementVisible.value = false;
        }

        const moveHome = () => {
            const updateURL = '/api/updateReplacementSkipDay';

            axios.post(updateURL, {
                id: props.corp_info.id,
            }).then(res => {
                modalReplacementVisible.value = false;

                const url ="/home";

                location.href = url;
            })
        }

        const successMessage = ref('');
        const showSuccess = ref(false);

        const showSuccessCharge = (msg) => {
            showSuccess.value = true;
            successMessage.value = msg;
            setTimeout(() => {
                showSuccess.value = false
            }, 3000);
        }

        const nextMode = ref(false);

        const showBackButton = ref(false);

        const commonReplacementRef = ref(null);

        const confirm = () => {
            if(commonReplacementRef.value){
                commonReplacementRef.value.confirmReplacement();
            }
        }

        const backMenu = () => {
            const url='/al/administrator/home';

            location.href = url;
        }

        const handleConfirmSuccess = (data) => {
            showSuccessCharge('読み替え登録が完了しました。');

            if(props.show_back_button === false){
                nextMode.value = true;
            }
        }

        const handleConfirmFailed = (data) => {
            console.log("失敗: " + data);
        }

        return {
            modalReplacementVisible,
            setLevel,
            closeModal,
            moveHome,

            successMessage,
            showSuccess,

            commonReplacementRef,

            showSuccessCharge,

            confirm,

            nextMode,

            showBackButton,

            backMenu,

            CommonReplacement,

            handleConfirmSuccess,
            handleConfirmFailed,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        organization_list: {
            type: Array,
        },
        executive_list: {
            type: Array,
        },
        management_list: {
            type: Array,
        },
        business_list: {
            type: Array,
        },
        client_list: {
            type: Array,
        },
        business_partner_list: {
            type: Array,
        },
        supplier_list: {
            type: Array,
        },
        cooperating_company_list: {
            type: Array,
        },
        product_list: {
            type: Array,
        },
        manufacturing_list: {
            type: Array,
        },
        production_list: {
            type: Array,
        },
        delivery_list: {
            type: Array,
        },
        raw_materials_list: {
            type: Array,
        },
        order_received_list: {
            type: Array,
        },
        order_list: {
            type: Array,
        },
        shipping_list: {
            type: Array,
        },
        our_company_list: {
            type: Array,
        },
        company_list: {
            type: Array,
        },
        in_house_list: {
            type: Array,
        },
        plant_list: {
            type: Array,
        },
        employee_list: {
            type: Array,
        },
        employment_list: {
            type: Array,
        },
        attendance_list: {
            type: Array,
        },
        earnings_list: {
            type: Array,
        },
        shareholder_list: {
            type: Array,
        },
        show_back_button: {
            type: Boolean,
        },
        replacement: {
            type:Array,
        },

    }
}
</script>

<style lang="scss" scoped>
    #replacement-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #content {
        z-index: 2;
        width: 30%;
        padding: 5em;
        background: #fff;
    }

</style>